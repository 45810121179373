// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-mnie-tsx": () => import("./../../../src/pages/o-mnie.tsx" /* webpackChunkName: "component---src-pages-o-mnie-tsx" */),
  "component---src-pages-psycholog-dla-mlodziezy-tsx": () => import("./../../../src/pages/psycholog-dla-mlodziezy.tsx" /* webpackChunkName: "component---src-pages-psycholog-dla-mlodziezy-tsx" */),
  "component---src-pages-rozwoj-osobisty-tsx": () => import("./../../../src/pages/rozwoj-osobisty.tsx" /* webpackChunkName: "component---src-pages-rozwoj-osobisty-tsx" */),
  "component---src-pages-wsparcie-malzenskie-tsx": () => import("./../../../src/pages/wsparcie-malzenskie.tsx" /* webpackChunkName: "component---src-pages-wsparcie-malzenskie-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-wpis-tsx": () => import("./../../../src/templates/wpis.tsx" /* webpackChunkName: "component---src-templates-wpis-tsx" */)
}

